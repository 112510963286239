export const BackgroundsSwisstainableIllustration = () => import('../../components/components/illustration/swisstainable/elements/BackgroundsSwisstainableIllustration.vue' /* webpackChunkName: "components/backgrounds-swisstainable-illustration" */).then(c => wrapFunctional(c.default || c))
export const BackpackSwisstainableIllustration = () => import('../../components/components/illustration/swisstainable/elements/BackpackSwisstainableIllustration.vue' /* webpackChunkName: "components/backpack-swisstainable-illustration" */).then(c => wrapFunctional(c.default || c))
export const BottlesSwisstainableIllustration = () => import('../../components/components/illustration/swisstainable/elements/BottlesSwisstainableIllustration.vue' /* webpackChunkName: "components/bottles-swisstainable-illustration" */).then(c => wrapFunctional(c.default || c))
export const BrowserFeedbackSwisstainableIllustration = () => import('../../components/components/illustration/swisstainable/elements/BrowserFeedbackSwisstainableIllustration.vue' /* webpackChunkName: "components/browser-feedback-swisstainable-illustration" */).then(c => wrapFunctional(c.default || c))
export const BrowserSwisstainableIllustration = () => import('../../components/components/illustration/swisstainable/elements/BrowserSwisstainableIllustration.vue' /* webpackChunkName: "components/browser-swisstainable-illustration" */).then(c => wrapFunctional(c.default || c))
export const CoupleGastroSwisstainableIllustration = () => import('../../components/components/illustration/swisstainable/elements/CoupleGastroSwisstainableIllustration.vue' /* webpackChunkName: "components/couple-gastro-swisstainable-illustration" */).then(c => wrapFunctional(c.default || c))
export const CoupleHotelSwisstainableIllustration = () => import('../../components/components/illustration/swisstainable/elements/CoupleHotelSwisstainableIllustration.vue' /* webpackChunkName: "components/couple-hotel-swisstainable-illustration" */).then(c => wrapFunctional(c.default || c))
export const CoupleLivingRoomSwisstainableIllustration = () => import('../../components/components/illustration/swisstainable/elements/CoupleLivingRoomSwisstainableIllustration.vue' /* webpackChunkName: "components/couple-living-room-swisstainable-illustration" */).then(c => wrapFunctional(c.default || c))
export const CoupleTrainSwisstainableIllustration = () => import('../../components/components/illustration/swisstainable/elements/CoupleTrainSwisstainableIllustration.vue' /* webpackChunkName: "components/couple-train-swisstainable-illustration" */).then(c => wrapFunctional(c.default || c))
export const DeepDiveAwardSwisstainableIllustration = () => import('../../components/components/illustration/swisstainable/elements/DeepDiveAwardSwisstainableIllustration.vue' /* webpackChunkName: "components/deep-dive-award-swisstainable-illustration" */).then(c => wrapFunctional(c.default || c))
export const DeepDiveCableCarSwisstainableIllustration = () => import('../../components/components/illustration/swisstainable/elements/DeepDiveCableCarSwisstainableIllustration.vue' /* webpackChunkName: "components/deep-dive-cable-car-swisstainable-illustration" */).then(c => wrapFunctional(c.default || c))
export const DeepDiveCycleSwisstainableIllustration = () => import('../../components/components/illustration/swisstainable/elements/DeepDiveCycleSwisstainableIllustration.vue' /* webpackChunkName: "components/deep-dive-cycle-swisstainable-illustration" */).then(c => wrapFunctional(c.default || c))
export const DeepDiveHandSmallSwisstainableIllustration = () => import('../../components/components/illustration/swisstainable/elements/DeepDiveHandSmallSwisstainableIllustration.vue' /* webpackChunkName: "components/deep-dive-hand-small-swisstainable-illustration" */).then(c => wrapFunctional(c.default || c))
export const DeepDiveHandSwisstainableIllustration = () => import('../../components/components/illustration/swisstainable/elements/DeepDiveHandSwisstainableIllustration.vue' /* webpackChunkName: "components/deep-dive-hand-swisstainable-illustration" */).then(c => wrapFunctional(c.default || c))
export const DeepDiveLabelSwisstainableIllustration = () => import('../../components/components/illustration/swisstainable/elements/DeepDiveLabelSwisstainableIllustration.vue' /* webpackChunkName: "components/deep-dive-label-swisstainable-illustration" */).then(c => wrapFunctional(c.default || c))
export const DeepDiveLeavesSwisstainableIllustration = () => import('../../components/components/illustration/swisstainable/elements/DeepDiveLeavesSwisstainableIllustration.vue' /* webpackChunkName: "components/deep-dive-leaves-swisstainable-illustration" */).then(c => wrapFunctional(c.default || c))
export const DeepDiveShipSwisstainableIllustration = () => import('../../components/components/illustration/swisstainable/elements/DeepDiveShipSwisstainableIllustration.vue' /* webpackChunkName: "components/deep-dive-ship-swisstainable-illustration" */).then(c => wrapFunctional(c.default || c))
export const DeepDiveTrainSwisstainableIllustration = () => import('../../components/components/illustration/swisstainable/elements/DeepDiveTrainSwisstainableIllustration.vue' /* webpackChunkName: "components/deep-dive-train-swisstainable-illustration" */).then(c => wrapFunctional(c.default || c))
export const DefsSwisstainableIllustration = () => import('../../components/components/illustration/swisstainable/elements/DefsSwisstainableIllustration.vue' /* webpackChunkName: "components/defs-swisstainable-illustration" */).then(c => wrapFunctional(c.default || c))
export const DoorSwisstainableIllustration = () => import('../../components/components/illustration/swisstainable/elements/DoorSwisstainableIllustration.vue' /* webpackChunkName: "components/door-swisstainable-illustration" */).then(c => wrapFunctional(c.default || c))
export const IconFeedbackSwisstainableIllustration = () => import('../../components/components/illustration/swisstainable/elements/IconFeedbackSwisstainableIllustration.vue' /* webpackChunkName: "components/icon-feedback-swisstainable-illustration" */).then(c => wrapFunctional(c.default || c))
export const IconGastroSwisstainableIllustration = () => import('../../components/components/illustration/swisstainable/elements/IconGastroSwisstainableIllustration.vue' /* webpackChunkName: "components/icon-gastro-swisstainable-illustration" */).then(c => wrapFunctional(c.default || c))
export const IconLeafSwisstainableIllustration = () => import('../../components/components/illustration/swisstainable/elements/IconLeafSwisstainableIllustration.vue' /* webpackChunkName: "components/icon-leaf-swisstainable-illustration" */).then(c => wrapFunctional(c.default || c))
export const IconPlaceSwisstainableIllustration = () => import('../../components/components/illustration/swisstainable/elements/IconPlaceSwisstainableIllustration.vue' /* webpackChunkName: "components/icon-place-swisstainable-illustration" */).then(c => wrapFunctional(c.default || c))
export const IconSpeechBubbleSwisstainableIllustration = () => import('../../components/components/illustration/swisstainable/elements/IconSpeechBubbleSwisstainableIllustration.vue' /* webpackChunkName: "components/icon-speech-bubble-swisstainable-illustration" */).then(c => wrapFunctional(c.default || c))
export const IconStarSwisstainableIllustration = () => import('../../components/components/illustration/swisstainable/elements/IconStarSwisstainableIllustration.vue' /* webpackChunkName: "components/icon-star-swisstainable-illustration" */).then(c => wrapFunctional(c.default || c))
export const LeafSwisstainableIllustration = () => import('../../components/components/illustration/swisstainable/elements/LeafSwisstainableIllustration.vue' /* webpackChunkName: "components/leaf-swisstainable-illustration" */).then(c => wrapFunctional(c.default || c))
export const LikeThumbSwisstainableIllustration = () => import('../../components/components/illustration/swisstainable/elements/LikeThumbSwisstainableIllustration.vue' /* webpackChunkName: "components/like-thumb-swisstainable-illustration" */).then(c => wrapFunctional(c.default || c))
export const LineElementsSwisstainableIllustration = () => import('../../components/components/illustration/swisstainable/elements/LineElementsSwisstainableIllustration.vue' /* webpackChunkName: "components/line-elements-swisstainable-illustration" */).then(c => wrapFunctional(c.default || c))
export const LineSwisstainableIllustration = () => import('../../components/components/illustration/swisstainable/elements/LineSwisstainableIllustration.vue' /* webpackChunkName: "components/line-swisstainable-illustration" */).then(c => wrapFunctional(c.default || c))
export const PhoneSwisstainableIllustration = () => import('../../components/components/illustration/swisstainable/elements/PhoneSwisstainableIllustration.vue' /* webpackChunkName: "components/phone-swisstainable-illustration" */).then(c => wrapFunctional(c.default || c))
export const PlantSwisstainableIllustration = () => import('../../components/components/illustration/swisstainable/elements/PlantSwisstainableIllustration.vue' /* webpackChunkName: "components/plant-swisstainable-illustration" */).then(c => wrapFunctional(c.default || c))
export const PosterCableCarSwisstainableIllustration = () => import('../../components/components/illustration/swisstainable/elements/PosterCableCarSwisstainableIllustration.vue' /* webpackChunkName: "components/poster-cable-car-swisstainable-illustration" */).then(c => wrapFunctional(c.default || c))
export const PosterLandscapeSwisstainableIllustration = () => import('../../components/components/illustration/swisstainable/elements/PosterLandscapeSwisstainableIllustration.vue' /* webpackChunkName: "components/poster-landscape-swisstainable-illustration" */).then(c => wrapFunctional(c.default || c))
export const RatingStarsSwisstainableIllustration = () => import('../../components/components/illustration/swisstainable/elements/RatingStarsSwisstainableIllustration.vue' /* webpackChunkName: "components/rating-stars-swisstainable-illustration" */).then(c => wrapFunctional(c.default || c))
export const ReceptionSwisstainableIllustration = () => import('../../components/components/illustration/swisstainable/elements/ReceptionSwisstainableIllustration.vue' /* webpackChunkName: "components/reception-swisstainable-illustration" */).then(c => wrapFunctional(c.default || c))
export const ReceptionistSwisstainableIllustration = () => import('../../components/components/illustration/swisstainable/elements/ReceptionistSwisstainableIllustration.vue' /* webpackChunkName: "components/receptionist-swisstainable-illustration" */).then(c => wrapFunctional(c.default || c))
export const SofaSwisstainableIllustration = () => import('../../components/components/illustration/swisstainable/elements/SofaSwisstainableIllustration.vue' /* webpackChunkName: "components/sofa-swisstainable-illustration" */).then(c => wrapFunctional(c.default || c))
export const TableSwisstainableIllustration = () => import('../../components/components/illustration/swisstainable/elements/TableSwisstainableIllustration.vue' /* webpackChunkName: "components/table-swisstainable-illustration" */).then(c => wrapFunctional(c.default || c))
export const TrainCompartmentSwisstainableIllustration = () => import('../../components/components/illustration/swisstainable/elements/TrainCompartmentSwisstainableIllustration.vue' /* webpackChunkName: "components/train-compartment-swisstainable-illustration" */).then(c => wrapFunctional(c.default || c))
export const WindowSwisstainableIllustration = () => import('../../components/components/illustration/swisstainable/elements/WindowSwisstainableIllustration.vue' /* webpackChunkName: "components/window-swisstainable-illustration" */).then(c => wrapFunctional(c.default || c))
export const DeepDive = () => import('../../components/components/illustration/swisstainable/DeepDive.vue' /* webpackChunkName: "components/deep-dive" */).then(c => wrapFunctional(c.default || c))
export const Milestone = () => import('../../components/components/illustration/swisstainable/Milestone.vue' /* webpackChunkName: "components/milestone" */).then(c => wrapFunctional(c.default || c))
export const MilestoneMarker = () => import('../../components/components/illustration/swisstainable/MilestoneMarker.vue' /* webpackChunkName: "components/milestone-marker" */).then(c => wrapFunctional(c.default || c))
export const SpeechBubble = () => import('../../components/components/illustration/swisstainable/SpeechBubble.vue' /* webpackChunkName: "components/speech-bubble" */).then(c => wrapFunctional(c.default || c))
export const Step = () => import('../../components/components/illustration/swisstainable/Step.vue' /* webpackChunkName: "components/step" */).then(c => wrapFunctional(c.default || c))
export const MobileMenu = () => import('../../components/general/navigation/MobileMenu.vue' /* webpackChunkName: "components/mobile-menu" */).then(c => wrapFunctional(c.default || c))
export const MobileMenuButton = () => import('../../components/general/navigation/MobileMenuButton.vue' /* webpackChunkName: "components/mobile-menu-button" */).then(c => wrapFunctional(c.default || c))
export const MobileNavItem = () => import('../../components/general/navigation/MobileNavItem.vue' /* webpackChunkName: "components/mobile-nav-item" */).then(c => wrapFunctional(c.default || c))
export const MobileNavItemWithSubmenu = () => import('../../components/general/navigation/MobileNavItemWithSubmenu.vue' /* webpackChunkName: "components/mobile-nav-item-with-submenu" */).then(c => wrapFunctional(c.default || c))
export const NavDropdown = () => import('../../components/general/navigation/NavDropdown.vue' /* webpackChunkName: "components/nav-dropdown" */).then(c => wrapFunctional(c.default || c))
export const NavItem = () => import('../../components/general/navigation/NavItem.vue' /* webpackChunkName: "components/nav-item" */).then(c => wrapFunctional(c.default || c))
export const Navigation = () => import('../../components/general/navigation/Navigation.vue' /* webpackChunkName: "components/navigation" */).then(c => wrapFunctional(c.default || c))
export const Sidebar = () => import('../../components/general/navigation/Sidebar.vue' /* webpackChunkName: "components/sidebar" */).then(c => wrapFunctional(c.default || c))
export const SidebarNavigation = () => import('../../components/general/navigation/SidebarNavigation.vue' /* webpackChunkName: "components/sidebar-navigation" */).then(c => wrapFunctional(c.default || c))
export const PageHasHeroWithImage = () => import('../../components/general/page-layout/PageHasHeroWithImage.vue' /* webpackChunkName: "components/page-has-hero-with-image" */).then(c => wrapFunctional(c.default || c))
export const PageHasHeroWithoutImage = () => import('../../components/general/page-layout/PageHasHeroWithoutImage.vue' /* webpackChunkName: "components/page-has-hero-without-image" */).then(c => wrapFunctional(c.default || c))
export const PageLayout = () => import('../../components/general/page-layout/PageLayout.vue' /* webpackChunkName: "components/page-layout" */).then(c => wrapFunctional(c.default || c))
export const PageLevel1 = () => import('../../components/general/page-layout/PageLevel1.vue' /* webpackChunkName: "components/page-level1" */).then(c => wrapFunctional(c.default || c))
export const Checkboxes = () => import('../../components/general/form-elements/Checkboxes.vue' /* webpackChunkName: "components/checkboxes" */).then(c => wrapFunctional(c.default || c))
export const InputAssets = () => import('../../components/general/form-elements/InputAssets.vue' /* webpackChunkName: "components/input-assets" */).then(c => wrapFunctional(c.default || c))
export const InputField = () => import('../../components/general/form-elements/InputField.vue' /* webpackChunkName: "components/input-field" */).then(c => wrapFunctional(c.default || c))
export const RadioButtons = () => import('../../components/general/form-elements/RadioButtons.vue' /* webpackChunkName: "components/radio-buttons" */).then(c => wrapFunctional(c.default || c))
export const Select = () => import('../../components/general/form-elements/Select.vue' /* webpackChunkName: "components/select" */).then(c => wrapFunctional(c.default || c))
export const NewsCardPlaceholder = () => import('../../components/general/placeholders/NewsCardPlaceholder.vue' /* webpackChunkName: "components/news-card-placeholder" */).then(c => wrapFunctional(c.default || c))
export const OfferCardPlaceholder = () => import('../../components/general/placeholders/OfferCardPlaceholder.vue' /* webpackChunkName: "components/offer-card-placeholder" */).then(c => wrapFunctional(c.default || c))
export const ProductCardPlaceholder = () => import('../../components/general/placeholders/ProductCardPlaceholder.vue' /* webpackChunkName: "components/product-card-placeholder" */).then(c => wrapFunctional(c.default || c))
export const TeamMemberCardPlaceholder = () => import('../../components/general/placeholders/TeamMemberCardPlaceholder.vue' /* webpackChunkName: "components/team-member-card-placeholder" */).then(c => wrapFunctional(c.default || c))
export const Accordion = () => import('../../components/components/accordion/Accordion.vue' /* webpackChunkName: "components/accordion" */).then(c => wrapFunctional(c.default || c))
export const AccordionItem = () => import('../../components/components/accordion/AccordionItem.vue' /* webpackChunkName: "components/accordion-item" */).then(c => wrapFunctional(c.default || c))
export const EventBlock = () => import('../../components/components/events/EventBlock.vue' /* webpackChunkName: "components/event-block" */).then(c => wrapFunctional(c.default || c))
export const EventCard = () => import('../../components/components/events/EventCard.vue' /* webpackChunkName: "components/event-card" */).then(c => wrapFunctional(c.default || c))
export const EventFilter = () => import('../../components/components/events/EventFilter.vue' /* webpackChunkName: "components/event-filter" */).then(c => wrapFunctional(c.default || c))
export const EventHeader = () => import('../../components/components/events/EventHeader.vue' /* webpackChunkName: "components/event-header" */).then(c => wrapFunctional(c.default || c))
export const Events = () => import('../../components/components/events/Events.vue' /* webpackChunkName: "components/events" */).then(c => wrapFunctional(c.default || c))
export const Card = () => import('../../components/components/cards/Card.vue' /* webpackChunkName: "components/card" */).then(c => wrapFunctional(c.default || c))
export const Cards = () => import('../../components/components/cards/Cards.vue' /* webpackChunkName: "components/cards" */).then(c => wrapFunctional(c.default || c))
export const ListAndTables = () => import('../../components/components/list-and-tables/ListAndTables.vue' /* webpackChunkName: "components/list-and-tables" */).then(c => wrapFunctional(c.default || c))
export const OpenCloseLabel = () => import('../../components/components/list-and-tables/OpenCloseLabel.vue' /* webpackChunkName: "components/open-close-label" */).then(c => wrapFunctional(c.default || c))
export const TableCustom = () => import('../../components/components/list-and-tables/TableCustom.vue' /* webpackChunkName: "components/table-custom" */).then(c => wrapFunctional(c.default || c))
export const FacebookSocialIcon = () => import('../../components/icons/social/FacebookSocialIcon.vue' /* webpackChunkName: "components/facebook-social-icon" */).then(c => wrapFunctional(c.default || c))
export const InstagramSocialIcon = () => import('../../components/icons/social/InstagramSocialIcon.vue' /* webpackChunkName: "components/instagram-social-icon" */).then(c => wrapFunctional(c.default || c))
export const LinkedinSocialIcon = () => import('../../components/icons/social/LinkedinSocialIcon.vue' /* webpackChunkName: "components/linkedin-social-icon" */).then(c => wrapFunctional(c.default || c))
export const OkgoSocialIcon = () => import('../../components/icons/social/OkgoSocialIcon.vue' /* webpackChunkName: "components/okgo-social-icon" */).then(c => wrapFunctional(c.default || c))
export const TwitterSocialIcon = () => import('../../components/icons/social/TwitterSocialIcon.vue' /* webpackChunkName: "components/twitter-social-icon" */).then(c => wrapFunctional(c.default || c))
export const BlueprintLoader = () => import('../../components/loaders/BlueprintLoader.vue' /* webpackChunkName: "components/blueprint-loader" */).then(c => wrapFunctional(c.default || c))
export const ComponentLoader = () => import('../../components/loaders/ComponentLoader.vue' /* webpackChunkName: "components/component-loader" */).then(c => wrapFunctional(c.default || c))
export const HeroLoader = () => import('../../components/loaders/HeroLoader.vue' /* webpackChunkName: "components/hero-loader" */).then(c => wrapFunctional(c.default || c))
export const BlogPostBlueprint = () => import('../../components/blueprints/BlogPostBlueprint.vue' /* webpackChunkName: "components/blog-post-blueprint" */).then(c => wrapFunctional(c.default || c))
export const EventsDetailBlueprint = () => import('../../components/blueprints/EventsDetailBlueprint.vue' /* webpackChunkName: "components/events-detail-blueprint" */).then(c => wrapFunctional(c.default || c))
export const OfferBlueprint = () => import('../../components/blueprints/OfferBlueprint.vue' /* webpackChunkName: "components/offer-blueprint" */).then(c => wrapFunctional(c.default || c))
export const PageBlueprint = () => import('../../components/blueprints/PageBlueprint.vue' /* webpackChunkName: "components/page-blueprint" */).then(c => wrapFunctional(c.default || c))
export const PartnerBlueprint = () => import('../../components/blueprints/PartnerBlueprint.vue' /* webpackChunkName: "components/partner-blueprint" */).then(c => wrapFunctional(c.default || c))
export const SwisstainableHubDetailpageBlueprint = () => import('../../components/blueprints/SwisstainableHubDetailpageBlueprint.vue' /* webpackChunkName: "components/swisstainable-hub-detailpage-blueprint" */).then(c => wrapFunctional(c.default || c))
export const TeamBlueprint = () => import('../../components/blueprints/TeamBlueprint.vue' /* webpackChunkName: "components/team-blueprint" */).then(c => wrapFunctional(c.default || c))
export const AnimatedContainer = () => import('../../components/general/AnimatedContainer.vue' /* webpackChunkName: "components/animated-container" */).then(c => wrapFunctional(c.default || c))
export const AppButton = () => import('../../components/general/AppButton.vue' /* webpackChunkName: "components/app-button" */).then(c => wrapFunctional(c.default || c))
export const AppFooter = () => import('../../components/general/AppFooter.vue' /* webpackChunkName: "components/app-footer" */).then(c => wrapFunctional(c.default || c))
export const AppLink = () => import('../../components/general/AppLink.vue' /* webpackChunkName: "components/app-link" */).then(c => wrapFunctional(c.default || c))
export const ArrowLink = () => import('../../components/general/ArrowLink.vue' /* webpackChunkName: "components/arrow-link" */).then(c => wrapFunctional(c.default || c))
export const AuthorCard = () => import('../../components/general/AuthorCard.vue' /* webpackChunkName: "components/author-card" */).then(c => wrapFunctional(c.default || c))
export const Authors = () => import('../../components/general/Authors.vue' /* webpackChunkName: "components/authors" */).then(c => wrapFunctional(c.default || c))
export const BlogOverview = () => import('../../components/general/BlogOverview.vue' /* webpackChunkName: "components/blog-overview" */).then(c => wrapFunctional(c.default || c))
export const BlogPagination = () => import('../../components/general/BlogPagination.vue' /* webpackChunkName: "components/blog-pagination" */).then(c => wrapFunctional(c.default || c))
export const BlogPostCard = () => import('../../components/general/BlogPostCard.vue' /* webpackChunkName: "components/blog-post-card" */).then(c => wrapFunctional(c.default || c))
export const BreadcrumbBackNavigation = () => import('../../components/general/BreadcrumbBackNavigation.vue' /* webpackChunkName: "components/breadcrumb-back-navigation" */).then(c => wrapFunctional(c.default || c))
export const Breadcrumbs = () => import('../../components/general/Breadcrumbs.vue' /* webpackChunkName: "components/breadcrumbs" */).then(c => wrapFunctional(c.default || c))
export const Breaky = () => import('../../components/general/Breaky.vue' /* webpackChunkName: "components/breaky" */).then(c => wrapFunctional(c.default || c))
export const Container = () => import('../../components/general/Container.vue' /* webpackChunkName: "components/container" */).then(c => wrapFunctional(c.default || c))
export const ContentSection = () => import('../../components/general/ContentSection.vue' /* webpackChunkName: "components/content-section" */).then(c => wrapFunctional(c.default || c))
export const Counter = () => import('../../components/general/Counter.vue' /* webpackChunkName: "components/counter" */).then(c => wrapFunctional(c.default || c))
export const FilterList = () => import('../../components/general/FilterList.vue' /* webpackChunkName: "components/filter-list" */).then(c => wrapFunctional(c.default || c))
export const FilterListMultiselect = () => import('../../components/general/FilterListMultiselect.vue' /* webpackChunkName: "components/filter-list-multiselect" */).then(c => wrapFunctional(c.default || c))
export const FormBuilder = () => import('../../components/general/FormBuilder.vue' /* webpackChunkName: "components/form-builder" */).then(c => wrapFunctional(c.default || c))
export const FormMessage = () => import('../../components/general/FormMessage.vue' /* webpackChunkName: "components/form-message" */).then(c => wrapFunctional(c.default || c))
export const GoogleMaps = () => import('../../components/general/GoogleMaps.vue' /* webpackChunkName: "components/google-maps" */).then(c => wrapFunctional(c.default || c))
export const HorizontalMouseScroll = () => import('../../components/general/HorizontalMouseScroll.vue' /* webpackChunkName: "components/horizontal-mouse-scroll" */).then(c => wrapFunctional(c.default || c))
export const ImageModal = () => import('../../components/general/ImageModal.vue' /* webpackChunkName: "components/image-modal" */).then(c => wrapFunctional(c.default || c))
export const LangSwitcher = () => import('../../components/general/LangSwitcher.vue' /* webpackChunkName: "components/lang-switcher" */).then(c => wrapFunctional(c.default || c))
export const LinkButton = () => import('../../components/general/LinkButton.vue' /* webpackChunkName: "components/link-button" */).then(c => wrapFunctional(c.default || c))
export const Logo = () => import('../../components/general/Logo.vue' /* webpackChunkName: "components/logo" */).then(c => wrapFunctional(c.default || c))
export const LogoWithoutText = () => import('../../components/general/LogoWithoutText.vue' /* webpackChunkName: "components/logo-without-text" */).then(c => wrapFunctional(c.default || c))
export const MaterialIcon = () => import('../../components/general/MaterialIcon.vue' /* webpackChunkName: "components/material-icon" */).then(c => wrapFunctional(c.default || c))
export const Pagination = () => import('../../components/general/Pagination.vue' /* webpackChunkName: "components/pagination" */).then(c => wrapFunctional(c.default || c))
export const PlusCollapsable = () => import('../../components/general/PlusCollapsable.vue' /* webpackChunkName: "components/plus-collapsable" */).then(c => wrapFunctional(c.default || c))
export const ProductOrderForm = () => import('../../components/general/ProductOrderForm.vue' /* webpackChunkName: "components/product-order-form" */).then(c => wrapFunctional(c.default || c))
export const SectionContainer = () => import('../../components/general/SectionContainer.vue' /* webpackChunkName: "components/section-container" */).then(c => wrapFunctional(c.default || c))
export const SkeletonLoader = () => import('../../components/general/SkeletonLoader.vue' /* webpackChunkName: "components/skeleton-loader" */).then(c => wrapFunctional(c.default || c))
export const Subtitle = () => import('../../components/general/Subtitle.vue' /* webpackChunkName: "components/subtitle" */).then(c => wrapFunctional(c.default || c))
export const SwisstainableHub = () => import('../../components/general/SwisstainableHub.vue' /* webpackChunkName: "components/swisstainable-hub" */).then(c => wrapFunctional(c.default || c))
export const TeamMemberCard = () => import('../../components/general/TeamMemberCard.vue' /* webpackChunkName: "components/team-member-card" */).then(c => wrapFunctional(c.default || c))
export const TeamMembers = () => import('../../components/general/TeamMembers.vue' /* webpackChunkName: "components/team-members" */).then(c => wrapFunctional(c.default || c))
export const Tooltip = () => import('../../components/general/Tooltip.vue' /* webpackChunkName: "components/tooltip" */).then(c => wrapFunctional(c.default || c))
export const TransitionExpand = () => import('../../components/general/TransitionExpand.vue' /* webpackChunkName: "components/transition-expand" */).then(c => wrapFunctional(c.default || c))
export const VideoPlayer = () => import('../../components/general/VideoPlayer.vue' /* webpackChunkName: "components/video-player" */).then(c => wrapFunctional(c.default || c))
export const CallToAction = () => import('../../components/components/CallToAction.vue' /* webpackChunkName: "components/call-to-action" */).then(c => wrapFunctional(c.default || c))
export const ConsultantsOverview = () => import('../../components/components/ConsultantsOverview.vue' /* webpackChunkName: "components/consultants-overview" */).then(c => wrapFunctional(c.default || c))
export const ContactCard = () => import('../../components/components/ContactCard.vue' /* webpackChunkName: "components/contact-card" */).then(c => wrapFunctional(c.default || c))
export const ContentSectionTitle = () => import('../../components/components/ContentSectionTitle.vue' /* webpackChunkName: "components/content-section-title" */).then(c => wrapFunctional(c.default || c))
export const CustomForm = () => import('../../components/components/CustomForm.vue' /* webpackChunkName: "components/custom-form" */).then(c => wrapFunctional(c.default || c))
export const CustomFormEventManager = () => import('../../components/components/CustomFormEventManager.vue' /* webpackChunkName: "components/custom-form-event-manager" */).then(c => wrapFunctional(c.default || c))
export const CustomIframe = () => import('../../components/components/CustomIframe.vue' /* webpackChunkName: "components/custom-iframe" */).then(c => wrapFunctional(c.default || c))
export const CustomMap = () => import('../../components/components/CustomMap.vue' /* webpackChunkName: "components/custom-map" */).then(c => wrapFunctional(c.default || c))
export const CustomerLogos = () => import('../../components/components/CustomerLogos.vue' /* webpackChunkName: "components/customer-logos" */).then(c => wrapFunctional(c.default || c))
export const DefaultImage = () => import('../../components/components/DefaultImage.vue' /* webpackChunkName: "components/default-image" */).then(c => wrapFunctional(c.default || c))
export const Downloads = () => import('../../components/components/Downloads.vue' /* webpackChunkName: "components/downloads" */).then(c => wrapFunctional(c.default || c))
export const EmbedCode = () => import('../../components/components/EmbedCode.vue' /* webpackChunkName: "components/embed-code" */).then(c => wrapFunctional(c.default || c))
export const FlipbookIntegration = () => import('../../components/components/FlipbookIntegration.vue' /* webpackChunkName: "components/flipbook-integration" */).then(c => wrapFunctional(c.default || c))
export const GeneralText = () => import('../../components/components/GeneralText.vue' /* webpackChunkName: "components/general-text" */).then(c => wrapFunctional(c.default || c))
export const IconCards = () => import('../../components/components/IconCards.vue' /* webpackChunkName: "components/icon-cards" */).then(c => wrapFunctional(c.default || c))
export const IframeSwisstainable = () => import('../../components/components/IframeSwisstainable.vue' /* webpackChunkName: "components/iframe-swisstainable" */).then(c => wrapFunctional(c.default || c))
export const Images = () => import('../../components/components/Images.vue' /* webpackChunkName: "components/images" */).then(c => wrapFunctional(c.default || c))
export const Links = () => import('../../components/components/Links.vue' /* webpackChunkName: "components/links" */).then(c => wrapFunctional(c.default || c))
export const LoadingSpinner = () => import('../../components/components/LoadingSpinner.vue' /* webpackChunkName: "components/loading-spinner" */).then(c => wrapFunctional(c.default || c))
export const MemberOffersOverview = () => import('../../components/components/MemberOffersOverview.vue' /* webpackChunkName: "components/member-offers-overview" */).then(c => wrapFunctional(c.default || c))
export const MoreOffers = () => import('../../components/components/MoreOffers.vue' /* webpackChunkName: "components/more-offers" */).then(c => wrapFunctional(c.default || c))
export const NewsSlider = () => import('../../components/components/NewsSlider.vue' /* webpackChunkName: "components/news-slider" */).then(c => wrapFunctional(c.default || c))
export const NewsletterSignUp = () => import('../../components/components/NewsletterSignUp.vue' /* webpackChunkName: "components/newsletter-sign-up" */).then(c => wrapFunctional(c.default || c))
export const ProductOverview = () => import('../../components/components/ProductOverview.vue' /* webpackChunkName: "components/product-overview" */).then(c => wrapFunctional(c.default || c))
export const ReplicatorWithSideMenu = () => import('../../components/components/ReplicatorWithSideMenu.vue' /* webpackChunkName: "components/replicator-with-side-menu" */).then(c => wrapFunctional(c.default || c))
export const SumaOffersCard = () => import('../../components/components/SumaOffersCard.vue' /* webpackChunkName: "components/suma-offers-card" */).then(c => wrapFunctional(c.default || c))
export const SumaOffersColumnCard = () => import('../../components/components/SumaOffersColumnCard.vue' /* webpackChunkName: "components/suma-offers-column-card" */).then(c => wrapFunctional(c.default || c))
export const SumaOffersOverview = () => import('../../components/components/SumaOffersOverview.vue' /* webpackChunkName: "components/suma-offers-overview" */).then(c => wrapFunctional(c.default || c))
export const SwisstainableDetailpageSlider = () => import('../../components/components/SwisstainableDetailpageSlider.vue' /* webpackChunkName: "components/swisstainable-detailpage-slider" */).then(c => wrapFunctional(c.default || c))
export const SwisstainableIllustration = () => import('../../components/components/SwisstainableIllustration.vue' /* webpackChunkName: "components/swisstainable-illustration" */).then(c => wrapFunctional(c.default || c))
export const TeaserSmall = () => import('../../components/components/TeaserSmall.vue' /* webpackChunkName: "components/teaser-small" */).then(c => wrapFunctional(c.default || c))
export const TeaserWithImage = () => import('../../components/components/TeaserWithImage.vue' /* webpackChunkName: "components/teaser-with-image" */).then(c => wrapFunctional(c.default || c))
export const TestimonialSlider = () => import('../../components/components/TestimonialSlider.vue' /* webpackChunkName: "components/testimonial-slider" */).then(c => wrapFunctional(c.default || c))
export const Tiles = () => import('../../components/components/Tiles.vue' /* webpackChunkName: "components/tiles" */).then(c => wrapFunctional(c.default || c))
export const TwitterFeed = () => import('../../components/components/TwitterFeed.vue' /* webpackChunkName: "components/twitter-feed" */).then(c => wrapFunctional(c.default || c))
export const VideoDefault = () => import('../../components/components/VideoDefault.vue' /* webpackChunkName: "components/video-default" */).then(c => wrapFunctional(c.default || c))
export const ConsultantCard = () => import('../../components/cards/ConsultantCard.vue' /* webpackChunkName: "components/consultant-card" */).then(c => wrapFunctional(c.default || c))
export const MemberOfferCard = () => import('../../components/cards/MemberOfferCard.vue' /* webpackChunkName: "components/member-offer-card" */).then(c => wrapFunctional(c.default || c))
export const NewsCard = () => import('../../components/cards/NewsCard.vue' /* webpackChunkName: "components/news-card" */).then(c => wrapFunctional(c.default || c))
export const ProductCard = () => import('../../components/cards/ProductCard.vue' /* webpackChunkName: "components/product-card" */).then(c => wrapFunctional(c.default || c))
export const SwisstainableArticleCard = () => import('../../components/cards/SwisstainableArticleCard.vue' /* webpackChunkName: "components/swisstainable-article-card" */).then(c => wrapFunctional(c.default || c))
export const ArrowBigIcon = () => import('../../components/icons/ArrowBigIcon.vue' /* webpackChunkName: "components/arrow-big-icon" */).then(c => wrapFunctional(c.default || c))
export const ArrowSimpleIcon = () => import('../../components/icons/ArrowSimpleIcon.vue' /* webpackChunkName: "components/arrow-simple-icon" */).then(c => wrapFunctional(c.default || c))
export const ArrowSmallIcon = () => import('../../components/icons/ArrowSmallIcon.vue' /* webpackChunkName: "components/arrow-small-icon" */).then(c => wrapFunctional(c.default || c))
export const AttachmentIcon = () => import('../../components/icons/AttachmentIcon.vue' /* webpackChunkName: "components/attachment-icon" */).then(c => wrapFunctional(c.default || c))
export const CartIcon = () => import('../../components/icons/CartIcon.vue' /* webpackChunkName: "components/cart-icon" */).then(c => wrapFunctional(c.default || c))
export const ChevronDown = () => import('../../components/icons/ChevronDown.vue' /* webpackChunkName: "components/chevron-down" */).then(c => wrapFunctional(c.default || c))
export const CrossIcon = () => import('../../components/icons/CrossIcon.vue' /* webpackChunkName: "components/cross-icon" */).then(c => wrapFunctional(c.default || c))
export const DownloadIcon = () => import('../../components/icons/DownloadIcon.vue' /* webpackChunkName: "components/download-icon" */).then(c => wrapFunctional(c.default || c))
export const FileIcon = () => import('../../components/icons/FileIcon.vue' /* webpackChunkName: "components/file-icon" */).then(c => wrapFunctional(c.default || c))
export const LinkIcon = () => import('../../components/icons/LinkIcon.vue' /* webpackChunkName: "components/link-icon" */).then(c => wrapFunctional(c.default || c))
export const PinchIcon = () => import('../../components/icons/PinchIcon.vue' /* webpackChunkName: "components/pinch-icon" */).then(c => wrapFunctional(c.default || c))
export const PlayIcon = () => import('../../components/icons/PlayIcon.vue' /* webpackChunkName: "components/play-icon" */).then(c => wrapFunctional(c.default || c))
export const PlusIcon = () => import('../../components/icons/PlusIcon.vue' /* webpackChunkName: "components/plus-icon" */).then(c => wrapFunctional(c.default || c))
export const RefreshIcon = () => import('../../components/icons/RefreshIcon.vue' /* webpackChunkName: "components/refresh-icon" */).then(c => wrapFunctional(c.default || c))
export const SearchIcon = () => import('../../components/icons/SearchIcon.vue' /* webpackChunkName: "components/search-icon" */).then(c => wrapFunctional(c.default || c))
export const TrashIcon = () => import('../../components/icons/TrashIcon.vue' /* webpackChunkName: "components/trash-icon" */).then(c => wrapFunctional(c.default || c))
export const UploadIcon = () => import('../../components/icons/UploadIcon.vue' /* webpackChunkName: "components/upload-icon" */).then(c => wrapFunctional(c.default || c))
export const UserIcon = () => import('../../components/icons/UserIcon.vue' /* webpackChunkName: "components/user-icon" */).then(c => wrapFunctional(c.default || c))
export const HeroBlogOrEventDetail = () => import('../../components/heros/HeroBlogOrEventDetail.vue' /* webpackChunkName: "components/hero-blog-or-event-detail" */).then(c => wrapFunctional(c.default || c))
export const HeroContact = () => import('../../components/heros/HeroContact.vue' /* webpackChunkName: "components/hero-contact" */).then(c => wrapFunctional(c.default || c))
export const HeroContentpage = () => import('../../components/heros/HeroContentpage.vue' /* webpackChunkName: "components/hero-contentpage" */).then(c => wrapFunctional(c.default || c))
export const HeroDetailpage = () => import('../../components/heros/HeroDetailpage.vue' /* webpackChunkName: "components/hero-detailpage" */).then(c => wrapFunctional(c.default || c))
export const HeroHomepage = () => import('../../components/heros/HeroHomepage.vue' /* webpackChunkName: "components/hero-homepage" */).then(c => wrapFunctional(c.default || c))
export const HeroNews = () => import('../../components/heros/HeroNews.vue' /* webpackChunkName: "components/hero-news" */).then(c => wrapFunctional(c.default || c))
export const HeroSimple = () => import('../../components/heros/HeroSimple.vue' /* webpackChunkName: "components/hero-simple" */).then(c => wrapFunctional(c.default || c))
export const HeroSuma = () => import('../../components/heros/HeroSuma.vue' /* webpackChunkName: "components/hero-suma" */).then(c => wrapFunctional(c.default || c))
export const HeroSumaDetailpage = () => import('../../components/heros/HeroSumaDetailpage.vue' /* webpackChunkName: "components/hero-suma-detailpage" */).then(c => wrapFunctional(c.default || c))
export const HeroSwisstainableDetailpage = () => import('../../components/heros/HeroSwisstainableDetailpage.vue' /* webpackChunkName: "components/hero-swisstainable-detailpage" */).then(c => wrapFunctional(c.default || c))
export const Scrolly = () => import('../../components/heros/Scrolly.vue' /* webpackChunkName: "components/scrolly" */).then(c => wrapFunctional(c.default || c))
export const ScrollyBig = () => import('../../components/heros/ScrollyBig.vue' /* webpackChunkName: "components/scrolly-big" */).then(c => wrapFunctional(c.default || c))
export const MobileSlider = () => import('../../components/MobileSlider.vue' /* webpackChunkName: "components/mobile-slider" */).then(c => wrapFunctional(c.default || c))

// nuxt/nuxt.js#8607
function wrapFunctional(options) {
  if (!options || !options.functional) {
    return options
  }

  const propKeys = Array.isArray(options.props) ? options.props : Object.keys(options.props || {})

  return {
    render(h) {
      const attrs = {}
      const props = {}

      for (const key in this.$attrs) {
        if (propKeys.includes(key)) {
          props[key] = this.$attrs[key]
        } else {
          attrs[key] = this.$attrs[key]
        }
      }

      return h(options, {
        on: this.$listeners,
        attrs,
        props,
        scopedSlots: this.$scopedSlots,
      }, this.$slots.default)
    }
  }
}
